* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style: none
}

a {
  text-decoration: none
}

html {
  overflow-x: hidden;
}

.slick-next {
  display: none !important;
}

.slick-next:before {
  display: none !important;
}

/* NEUE MONTREAL FONTS */
@font-face {
  font-family: "Neue Montreal";
  src: url("./fonts/NeueMontreal-Light.woff2") format("woff2"),
    url("./fonts/NeueMontreal-Regular.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Neue Montreal";
  src: url("./fonts/NeueMontreal-Regular.woff2") format("woff2"),
    url("./fonts/NeueMontreal-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Neue Montreal";
  src: url("./fonts/NeueMontreal-Medium.woff2") format("woff2"),
    url("./fonts/NeueMontreal-Medium.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Neue Montreal";
  src: url("./fonts/NeueMontreal-Bold.woff2") format("woff2"),
    url("./fonts/NeueMontreal-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

/* ATYP DISPLAY FONTS */
@font-face {
  font-family: "Atyp Display";
  src: url("./fonts/AtypDisplay-Light.woff2") format("woff2"),
    url("./fonts/AtypDisplay-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Atyp Display";
  src: url("./fonts/AtypDisplay-Regular.woff2") format("woff2"),
    url("./fonts/AtypDisplay-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Atyp Display";
  src: url("./fonts/AtypDisplay-Medium.woff2") format("woff2"),
    url("./fonts/AtypDisplay-Medium.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Atyp Display";
  src: url("./fonts/AtypDisplay-Semibold.woff2") format("woff2"),
    url("./fonts/AtypDisplay-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Atyp Display";
  src: url("./fonts/AtypDisplay-Bold.woff2") format("woff2"),
    url("./fonts/AtypDisplay-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@mixin font-big {
  font-family: "Atyp Display";
  font-size: 64px;
  font-weight: 600;
  line-height: 64px;
  letter-spacing: -0.02em;
}

@mixin font-small {
  font-family: 'Neue Montreal';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
}

@mixin font-title {
  font-family: 'Atyp Display';
  font-size: 52px;
  font-weight: 700;
  line-height: 52px;
  letter-spacing: -0.02em;
}

@mixin font-subtitle {
  font-family: 'Neue Montreal';
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
}

.d-flex {
  display: flex;
}

.jcsb {
  justify-content: space-between;
  align-items: center;
  align-self: center;
}

::-webkit-scrollbar {
  display: none;
}

.slick-slider {
  margin: 0 !important;
}

.slick-dots {
  display: none !important;
}

input {
  caret-color: #32CCCC;
  font-family: 'Neue Montreal';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
}

input::placeholder {
  color: rgba(226, 230, 230, 1);
}

button {
  cursor: pointer;
}

/* Стили для устройств с шириной экрана от 321px до 767px (например, мобильные телефоны в альбомной ориентации и планшеты) */
@media screen and (min-width: 321px) and (max-width: 767px) {
  body {
    overflow-x: hidden;
  }

  .menuBtn {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);

    &-content {
      background-color: #fefefe;
      padding: 20px;
      width: 100%;
      height: 122vh;

      &-menu {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        background-image: url(./assets/card-bg.png);
        background-repeat: no-repeat;
        background-position: top right;
        position: relative;
        left: 20px;

        &_li {

          a {
            font-family: 'Atyp Display';
            font-size: 24px;
            font-weight: 600;
            line-height: 45px;
            letter-spacing: 0em;
            color: rgba(4, 4, 4, 1);
          }
        }
      }

      &-menu2 {
        margin-top: 10px;
        width: 110%;
        margin-left: -5%;
        border-top: 1px dashed rgba(226, 230, 230, 1);
        padding-top: 4px;
        padding-bottom: 4px;

        &_li {
          margin-left: 10%;

          a {
            @include font-small;
            color: rgba(86, 89, 89, 1);
          }
        }
      }
    }

    &-enter {
      opacity: 0;

      &-active {
        opacity: 1;
        transition: opacity 1s;
      }
    }

    &-exit {
      opacity: 1;

      &-active {
        opacity: 0;
        transition: opacity 1s;
      }
    }
  }

  .modal {
    display: none;
    position: fixed;
    z-index: 2000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);

    &-content {
      background-color: #fefefe;
      padding: 20px;
      width: 100%;
      height: 100%;

      &-text {
        display: flex;
        flex-direction: column;
        align-items: center;

        &_title {
          font-family: 'Atyp Display';
          font-size: 24px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: -0.02em;
          text-align: center;
          margin-top: 64px;
          width: 90%;
        }

        &_sub {
          font-family: 'Neue Montreal';
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.01em;
          text-align: center;
          width: 90%;
          margin-top: 16px;
          margin-bottom: 32px;
        }
      }

      &-join {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        align-self: center;


        &_input {
          width: 90%;
          background: rgba(247, 249, 249, 1);
          border: 1px solid rgba(226, 230, 230, 1);
          border-radius: 24px;
          background-image: url(./assets/loginModalMail.png);
          background-repeat: no-repeat;
          background-position-y: center;
          background-position-x: 20px;
          padding: 12px 0 12px 52px;
          margin-bottom: 20px;
        }

        &_input:focus-visible {
          border: 1px solid #32CCCC !important;
          outline: none;
        }

        &_btn {
          width: 90%;
          @include font-small;
          font-weight: 600;
          border: none;
          background: rgba(50, 204, 204, 1);
          border-radius: 24px;
          padding: 13px 32px;
          text-align: center;
        }
      }
    }

    &-enter {
      opacity: 0;

      &-active {
        opacity: 1;
        transition: opacity 1s;
      }
    }

    &-exit {
      opacity: 1;

      &-active {
        opacity: 0;
        transition: opacity 1s;
      }
    }
  }

  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
  }

  .open {
    display: block;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 5.33%;

    &-menu {
      display: none;
    }

    &-login_btn {
      font-family: 'Neue Montreal';
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.01em;
      padding: 10px 24px;
      border: none;
      border-radius: 20px;
      background: rgba(50, 204, 204, 1);

      a {
        color: rgba(4, 4, 4, 1);
        transition: 0.3s;
      }

      a:hover {
        color: white
      }
    }
  }

  .sec1 {
    margin-top: 50px;
    background-image: url(./assets/headerbg-mobile.png);
    background-size: auto;
    background-repeat: no-repeat;
    padding-bottom: 390px;
    background-position: bottom center;

    &-left {
      margin-left: 7%;

      &_img {
        margin-top: 40px;
        margin-bottom: 16px;
      }

      &_title {
        font-family: 'Atyp Display';
        font-size: 36px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: -0.02em;
        width: 321px;

        span {
          color: #32CCCC;
        }
      }

      &_subtitle {
        @include font-small;
        margin-top: 16px;
        margin-bottom: 24px;
        width: 295px;
      }

      &_text {
        font-family: 'Neue Montreal';
        font-size: 13px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.01em;
        margin-bottom: 16px;
      }

      &_btn {
        @include font-small;
        font-weight: 600;
        border: none;
        border-radius: 26px;
        background: rgba(50, 204, 204, 1);
        color: rgba(4, 4, 4, 1);
        width: 160px;
        display: flex;
        padding: 16.5px 32px;
        justify-content: space-around;
        align-items: center;
        align-self: center;

        img {
          display: none;
        }
      }

      &_arrow {
        position: relative;
        left: 180px;
        top: -80px;
      }
    }
  }

  .secondary {
    margin-top: 60px;
    background: rgba(50, 204, 204, 0.1);
    padding: 60px 5.3% 80px;

    &-image {
      display: none;
    }

    &-main {

      &-left {

        &_text {

          &__title {
            font-family: 'Atyp Display';
            font-size: 32px;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: -0.02em;
            margin-top: 16px;
            margin-bottom: 24px;
            width: 335px;

            span {
              color: #32CCCC;
            }
          }

          &__subtitle {
            width: 335px;
            @include font-small;
            margin-bottom: 32px;
          }
        }

        &_btn {
          @include font-small;
          font-weight: 600;
          color: rgba(4, 4, 4, 1);
          background: rgba(50, 204, 204, 1);
          padding: 16.5px 32px;
          border: none;
          border-radius: 24px;
          transition: 0.5s;
          outline: none;
        }

        &_btn:hover {
          color: #fefefe !important;
        }
      }
    }
  }


  .sec2 {
    background: linear-gradient(180deg, #32CCCC 46.16%, rgba(255, 255, 255, 0) 100%);

    .slick-slider {
      height: 50vh;
    }

    .slick-list {
      overflow: initial;
    }

    .dots-container {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
    }

    .dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: rgba(50, 204, 204, 0.5);
      margin: 0 5px;
      cursor: pointer;
      transition: 0.5s;
    }

    .dot.active {
      background-color: rgba(50, 204, 204, 1);
      width: 20px;
      border-radius: 5px;
    }

    &-main {
      background-image: url(./assets/sec2-mobilebg.svg);
      background-repeat: no-repeat;
      background-size: auto;
      background-position: bottom center;
      padding-bottom: 300px;
      padding-top: 60px;

      &-left {
        margin-left: 5.3%;

        &_text {
          &__title {
            font-family: 'Atyp Display';
            font-size: 36px;
            font-weight: 600;
            line-height: 36px;
            letter-spacing: -0.02em;
            width: 248px;

            span {
              color: rgba(255, 255, 255, 1);
            }
          }

          &__subtitle {
            font-family: 'Neue Montreal';
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0px;
            width: 334px;
            margin-top: 16px;
            margin-bottom: 32px;
          }
        }

        &_btn {
          @include font-small;
          font-weight: 600;
          padding: 16.5px 32px;
          color: rgba(4, 4, 4, 1);
          background: rgba(255, 255, 255, 1);
          border-radius: 24px;
          border: none;
          margin-bottom: 40px;
        }
      }

      &-right {
        display: none;
      }
    }

    &-cards {
      display: flex;
      overflow: hidden;
      overflow-x: initial;
      margin-top: 60px;

    }
  }

  .card {
    padding: 20px;
    padding-bottom: 32px;
    background: #fff;
    border-radius: 12px;
    width: 90%;
    margin: 60px 24px 0 5.4%;
    border: 1px dashed rgba(226, 230, 230, 1);
    background-image: url(./assets/card-bg.png);
    background-repeat: no-repeat;
    background-position: top right;
    height: 284px;
    position: relative;

    &_icon {
      border: none;
    }

    &_title {
      font-family: 'Atyp Display';
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      letter-spacing: 0.01em;
      width: 172px;
      margin-top: 16px;
      margin-bottom: 12px;
    }

    &_subtitle {
      font-family: 'Neue Montreal';
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.01em;
      width: 222px;
    }
  }

  .sec3 {
    padding-top: 100px;

    &-main {
      padding-left: 5.3%;
      background-image: url(./assets/sec3-mobilebg.png);
      background-size: 70%;
      background-position: center bottom;
      background-repeat: no-repeat;
      padding-bottom: 50%;
      margin-bottom: 60px;

      &-left {


        &_text {
          margin-top: 16px;

          &__title {
            font-family: 'Atyp Display';
            font-size: 36px;
            font-weight: 600;
            line-height: 36px;
            letter-spacing: -0.02em;
            width: 248px;

            span {
              color: #32CCCC;
            }
          }

          &__subtitle {
            font-family: 'Neue Montreal';
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0px;
            width: 334px;
            margin-top: 16px;
            margin-bottom: 32px;
          }
        }

        &_btn {
          @include font-small;
          font-weight: 600;
          border: none;
          border-radius: 24px;
          background: rgba(50, 204, 204, 1);
          color: rgba(4, 4, 4, 1);
          margin-bottom: 60px;
          padding: 16.5px 32px;
        }
      }
    }

    &-cards {
      margin: auto 5.3%;

      &-row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    padding-bottom: 48px;
  }

  .SecondaryCard {
    padding: 20px;
    border: 1px dashed rgba(226, 230, 230, 1);
    border-radius: 12px;
    margin-bottom: 12px;

    &_bg {
      width: 100%;
    }

    &_text {
      &__title {
        font-family: 'Atyp Display';
        font-size: 20px;
        font-weight: 600;
        line-height: 31px;
        letter-spacing: 0.01em;
        margin-top: 24px;
        margin-bottom: 12px;
        width: 63%;
      }

      &__subtitle {
        font-family: 'Neue Montreal';
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.01em;
        width: 76.5%;
      }
    }
  }

  .sec4 {
    background-image: url(./assets/sec4mobile-mainbg.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 0 50px;

    .dots-container {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
    }

    .dot {
      width: 10px;
      height: 8px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.5);
      margin: 0 5px;
      cursor: pointer;
      transition: 0.5s;
    }

    .dot.active {
      background-color: rgba(255, 255, 255, 1);
      width: 24px;
      border-radius: 5px;
    }

    &-main {
      background-image: url(./assets/sec4-mobilesecond.png);
      background-repeat: no-repeat;
      background-position: center bottom;
      padding-bottom: 80%;
      background-size: 70%;
      overflow: hidden;
      margin: 0 5.33% 60px;

      &-left {
        img {
          margin-top: 60px;
        }

        &_text {
          margin-top: 16px;

          &__title {
            font-family: 'Atyp Display';
            font-size: 36px;
            font-weight: 600;
            line-height: 36px;
            letter-spacing: -0.02em;
            width: 241px;
          }

          &__subtitle {
            font-family: 'Neue Montreal';
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0px;
            margin-top: 16px;
            margin-bottom: 32px;
            width: 335px;
          }
        }

        &_btn {
          @include font-small;
          font-weight: 600;
          border: none;
          border-radius: 24px;
          background: rgba(255, 255, 255, 1);
          color: rgba(4, 4, 4, 1);
          padding: 16.5px 32px;
        }
      }
    }

    &-cards {
      &-row {
        width: 100%;
        display: flex;
        overflow: hidden;
      }
    }
  }

  .ThirdCard {
    padding: 24px;
    background: #fff;
    width: 90%;
    border: 1px dashed rgba(226, 230, 230, 1);
    border-radius: 12px;
    height: 282px;
    margin-bottom: 33px;
    background-image: url(./assets/card-bg.png);
    background-repeat: no-repeat;
    background-position: top right;

    &_title {
      font-family: 'Atyp Display';
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      letter-spacing: 0.01em;
      padding-top: 16px;
      padding-bottom: 12px;
      width: 211px;
    }

    &_subtitle {
      font-family: 'Neue Montreal';
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.01em;

      width: 222px;
    }

    &_image {
      position: relative;
      top: -100%;
      left: 61%;
    }
  }

  .collapse {
    padding: 16px 0;
    border-bottom: 1px dashed rgba(226, 230, 230, 1);
    width: 90%;
    margin: auto;
    overflow: hidden;
    transition: max-height 2s;
    max-height: 80px;

    &_title {
      width: 254px;
      font-family: 'Atyp Display';
      font-size: 32px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: -0.02em;
      margin-left: 5%;
      margin-top: 60px;
      margin-bottom: 40px;
    }

    &-main {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &_questions {
        font-family: 'Atyp Display';
        font-size: 18px;
        font-weight: 600;
        line-height: 23px;
        letter-spacing: 0.01em;
      }

      button {
        background: none;
        border: none;
      }
    }


    &-answer {
      padding: 10px;
      background-color: #fff;

      &_answer {
        @include font-small;
        display: none;
        width: 90%;
        margin-top: 8px;
        transition: 1s;
      }
    }
  }

  .collapse.open {
    max-height: 500px;
  }

  .collapse.open .collapse-answer_answer {
    display: block;
  }

  .footer {
    margin-bottom: 100px;
    margin-top: 100px;

    h1 {
      font-family: 'Atyp Display';
      font-size: 36px;
      font-weight: 600;
      line-height: 36px;
      letter-spacing: -0.02em;
      text-align: center;
      width: 199px;
      margin: auto;

      span {
        color: #32CCCC;
      }
    }

    button {
      margin: auto;
      margin-top: 32px;
    }
  }

  .contact {
    padding-top: 20px;
    margin-bottom: 100px;

    input,
    textarea {
      padding: 12px 0 12px 20px;
      border: 1px solid rgba(226, 230, 230, 1);
      background: rgba(247, 249, 249, 1);
      color: rgba(4, 4, 4, 1);
      border-radius: 24px;
      outline: none;
      font-family: 'Neue Montreal';
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
    }

    input::placeholder,
    textarea::placeholder {
      color: rgba(156, 160, 160, 1);
      font-family: 'Neue Montreal';
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
    }

    input.error,
    textarea.error {
      border: 2px solid rgba(235, 87, 87, 1);
      color: rgba(235, 87, 87, 1);
    }

    input.error::placeholder,
    textarea.error::placeholder {
      color: rgba(235, 87, 87, 1);
    }

    &-main {
      width: 80%;
      margin: auto;

      &_title {
        font-family: "Atyp Display";
        font-size: 32px;
        font-weight: bold;
        line-height: 32px;
        letter-spacing: -0.02em;
        text-align: center;
        margin-bottom: 12px;
      }

      &_subtitle {
        font-family: 'Neue Montreal';
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
      }
    }

    &-form {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      width: 90%;
      margin: auto;
      margin-top: 56px;

      &-info {
        width: 100%;
        display: flex;

        &_name {
          margin-right: 20px;
          width: 50%;
        }

        &_surname {
          width: 50%;
        }
      }

      &_email {
        width: 100%;
        margin-top: 24px;
      }

      &_message {
        width: 100%;
        height: 114px;
        margin-top: 24px;
        resize: none;
      }

      &_btn {
        width: 100%;
        padding-top: 12px;
        padding-bottom: 13px;
        border: none;
        color: rgba(4, 4, 4, 1);
        background: rgba(50, 204, 204, 1);
        border-radius: 24px;
        font-family: 'Neue Montreal';
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0.01em;
        margin-top: 32px;
        transition: 0.4s;
      }

      &_btn:hover {
        color: #fefefe;
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 40px 10%;

    .img {
      display: none;
    }

    &-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 90%;
      margin: auto;
      margin-bottom: 32px;

      &_li {
        list-style: none;

        a {
          font-family: 'Neue Montreal';
          font-size: 13px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0.01em;
          text-decoration: none;
          color: rgba(86, 89, 89, 1);
          transition: 1s;
        }

        a:hover {
          color: #32CCCC;
        }
      }
    }

    &-social {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: center;
      width: 170px;

      &_li {
        list-style: none;
      }
    }
  }

  .sec2-cards-container {
    position: relative;
    overflow-x: hidden;
  }

  .sec2-cards {
    width: 100%;
  }

  .sec4-cards-container {
    position: relative;
    overflow-x: hidden;
  }

  .sec4-cards {
    width: 100%;
  }

}

/* Стили для устройств с шириной экрана от 768px до 1023px (например, планшеты и небольшие ноутбуки) */
@media screen and (min-width: 768px) and (max-width: 1440px) {

  body {
    overflow-x: hidden;
  }

  .ThirdCard-1 {
    background-image: url(./assets/first-img-card.png);
    background-repeat: no-repeat;
    background-position: top right;
  }

  .ThirdCard-2 {
    background-image: url(./assets/sec-img-card.png);
    background-repeat: no-repeat;
    background-position: top right;
  }

  .ThirdCard-3 {
    background-image: url(./assets/thrd-img-card.png);
    background-repeat: no-repeat;
    background-position: top right;
  }

  .ThirdCard_image {
    display: none;
  }

  .menuBtn {
    display: none;
  }

  .header-menu_btn {
    display: none;
  }


  .modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);

    &-content {
      background-color: #fefefe;
      margin: 15% auto;
      padding: 20px;
      border: none;
      width: 512px;
      border-radius: 24px;
      padding: 12px 48px 96px;

      &-text {
        display: flex;
        flex-direction: column;
        align-items: center;

        &_title {
          font-family: 'Atyp Display';
          font-size: 36px;
          font-weight: 600;
          line-height: 36px;
          letter-spacing: -0.02em;
          margin-top: 64px;
          width: 272px;
        }

        &_sub {
          @include font-small;
          text-align: center;
          width: 352px;
          margin-top: 16px;
          margin-bottom: 32px;
        }
      }

      &-join {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        align-self: center;


        &_input {
          padding-top: 12px;
          padding-bottom: 12px;
          background: rgba(247, 249, 249, 1);
          border: 1px solid rgba(226, 230, 230, 1);
          border-radius: 24px;
          background-image: url(./assets/loginModalMail.png);
          background-repeat: no-repeat;
          background-position-y: center;
          background-position-x: 20px;
          padding-left: 52px;
        }

        &_input:focus-visible {
          border: 1px solid #32CCCC !important;
          outline: none;
        }

        &_btn {
          @include font-small;
          font-weight: 600;
          border: none;
          background: rgba(50, 204, 204, 1);
          border-radius: 24px;
          padding: 13px 32px;
        }
      }
    }

    &-enter {
      opacity: 0;

      &-active {
        opacity: 1;
        transition: opacity 1s;
      }
    }

    &-exit {
      opacity: 1;

      &-active {
        opacity: 0;
        transition: opacity 1s;
      }
    }
  }


  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
  }

  .open {
    display: block;
  }


  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    padding: 20px 7% 28px;

    &-menu {
      width: 257px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: center;
      margin-left: 48px;

      &_li {
        list-style: none;

        a {
          text-decoration: none;
          @include font-small;
          color: rgba(4, 4, 4, 1);
        }
      }
    }

    &-login {

      &_btn {
        border: 1.5px solid rgba(50, 204, 204, 1);
        padding: 13px 32px;
        background: none;
        border-radius: 24px;
        transition: 0.3s;

        a {
          @include font-small;
          text-decoration: none;
          color: rgba(4, 4, 4, 1);
        }
      }

      &_btn:hover {
        background: rgba(50, 204, 204, 1);
      }
    }
  }

  .sec1 {
    background-image: url(./assets/header-bg.png);
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center right;
    margin: 100px 7% 100px;

    &-left {

      &_img {
        margin-bottom: 16px;
      }

      &_title {
        font-family: 'Atyp Display';
        font-size: 36px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: -0.02em;
        width: 321px;

        span {
          color: #32CCCC;
        }
      }

      &_subtitle {
        @include font-small;
        margin-top: 16px;
        margin-bottom: 24px;
        width: 295px;
      }

      &_text {
        font-family: Neue Montreal;
        font-size: 13px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.01em;
        margin-bottom: 16px;
      }

      &_btn {
        @include font-small;
        font-weight: 600;
        border: none;
        border-radius: 26px;
        background: rgba(50, 204, 204, 1);
        color: rgba(4, 4, 4, 1);
        width: 160px;
        display: flex;
        padding: 16.5px 32px;
        justify-content: space-around;
        align-items: center;
        align-self: center;

        img {
          display: none;
        }
      }

      &_arrow {
        position: relative;
        left: 180px;
        top: -80px;
      }
    }
  }

  .secondary {
    display: flex;
    background-color: rgba(50, 204, 204, 0.1);
    padding: 0 7%;

    &-main {
      padding-top: 100px;
      padding-bottom: 100px;
      background-image: url(./assets/secondary-mainbg.png);
      background-repeat: no-repeat;
      background-position: center right;
      background-size: 50%;
      background-position-x: 120%;

      &-left {

        &_text {
          margin-top: 32px;
          margin-bottom: 39px;

          &__title {
            @include font-title;
            margin-bottom: 32px;
            width: 60%;

            span {
              color: rgba(50, 204, 204, 1);
            }
          }

          &__subtitle {
            @include font-subtitle;
            width: 629px;
          }
        }

        &_btn {
          @include font-small;
          font-weight: 600;
          padding: 16.5px 32px;
          border: none;
          border-radius: 26px;
          background: rgba(50, 204, 204, 1);
          transition: 0.4s;
        }

        &_btn:hover {
          color: #fff;
        }
      }
    }

  }

  .sec2 {
    background: linear-gradient(180deg, #32CCCC 14.06%, rgba(50, 204, 204, 0) 79.69%);
    padding: 100px 7%;

    &-main {
      background-image: url(./assets/sec2-mainbg.png);
      background-repeat: no-repeat;
      background-position: center right;
      padding-bottom: 100px;
      margin-bottom: 100px;
      background-size: 50%;

      &-left {
        &_text {
          &__title {
            @include font-big;
            color: rgba(4, 4, 4, 1);
            width: 440px;

            span {
              color: rgba(255, 255, 255, 1);
            }
          }

          &__subtitle {
            @include font-small;
            width: 421px;
            margin-top: 24px;
            margin-bottom: 40px;
          }
        }

        &_btn {
          padding: 16.5px 32px;
          border: none;
          border-radius: 26px;
          background: #FFF;
          @include font-small;
          font-weight: 600;
          transition: 0.4s;
        }

        &_btn:hover {
          color: rgba(50, 204, 204, 1);
        }
      }

      &-right {
        background-image: url(./assets/sec2-mainbg.png);
        background-repeat: no-repeat;
        background-size: contain;

        margin-top: 100px;
      }
    }

    &-cards {
      display: flex;
      margin: auto;
    }
  }

  .card {
    padding: 20px;
    background: #fff;
    border-radius: 12px;
    width: 90%;
    margin: 12px;
    border: 1px dashed rgba(226, 230, 230, 1);
    background-image: url(./assets/card-bg.png);
    background-repeat: no-repeat;
    background-position: top right;

    &_icon {
      border: none;
    }

    &_title {
      font-family: 'Atyp Display';
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      letter-spacing: 0.01em;
      width: 172px;
      margin-top: 16px;
      margin-bottom: 12px;
    }

    &_subtitle {
      font-family: 'Neue Montreal';
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.01em;
      width: 222px;
    }
  }

  .sec3 {
    margin: 0 7%;
    padding: 100px 0;

    &-main {
      background-image: url(./assets//sec3-mainbg.png);
      background-repeat: no-repeat;
      background-size: 50%;
      background-position: center right;

      &-left {
        &_text {
          &__title {
            @include font-big;
            margin-top: 30px;
            margin-bottom: 24px;
            width: 440px;

            span {
              color: rgba(50, 204, 204, 1);
            }
          }

          &__subtitle {
            @include font-small;
            margin-bottom: 40px;
            width: 421px;
          }
        }

        &_btn {
          @include font-small;
          font-weight: 600;
          padding: 16.5px 32px;
          border: none;
          border-radius: 26px;
          color: rgba(4, 4, 4, 1);
          background: rgba(50, 204, 204, 1);
          margin-bottom: 85px;
          transition: 0.4s;
        }

        &_btn:hover {
          color: #fff;
        }
      }
    }

    &-cards {
      margin-top: 100px;

      &-row {
        display: flex;
        margin: auto;
      }
    }
  }

  .SecondaryCard {
    margin: 12px;
    padding: 24px;
    border: 1px dashed rgba(226, 230, 230, 1);
    border-radius: 12px;
    width: 30%;

    &_bg {
      width: 100%;
    }

    &_text {
      &__title {
        font-family: 'Atyp Display';
        font-size: 16px;
        font-weight: 600;
        line-height: 31px;
        letter-spacing: 0.01em;
        margin-top: 24px;
        margin-bottom: 12px;
        width: 80%;
      }

      &__subtitle {
        font-family: 'Neue Montreal';
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.01em;
        width: 100%;
      }
    }
  }

  .sec4 {
    padding: 100px 7%;
    background-image: url(./assets/sec4-mainbg.png);
    background-repeat: no-repeat;
    background-size: cover;

    &-main {
      background-image: url(./assets/sec4-seconbg.png);
      background-repeat: no-repeat;
      background-position: center right;
      background-size: 50%;
      padding-bottom: 95px;
      margin-bottom: 90px;

      &-left {
        &_text {
          &__title {
            @include font-big;
            color: rgba(4, 4, 4, 1);
            width: 428px;

            span {
              color: rgba(255, 255, 255, 1);
            }
          }

          &__subtitle {
            @include font-small;
            margin-top: 24px;
            margin-bottom: 40px;
            width: 421px;
          }
        }

        &_btn {
          font-family: 'Neue Montreal';
          font-size: 16px;
          font-weight: 600;
          line-height: 19px;
          letter-spacing: 0em;
          border: none;
          border-radius: 26px;
          color: rgba(4, 4, 4, 1);
          background: rgba(255, 255, 255, 1);
          padding: 16.5px 32px;
          transition: 0.4s;
        }

        &_btn:hover {
          color: rgba(50, 204, 204, 1);
        }
      }
    }

    &-cards {
      &-row {
        display: flex;
        margin: auto;
      }
    }
  }

  .ThirdCard {
    padding: 24px;
    background-color: #fff;
    width: 31%;
    border: 1px dashed rgba(226, 230, 230, 1);
    border-radius: 12px;
    height: 282px;
    margin: 12px;
    margin-bottom: 50px;

    &_title {
      font-family: 'Atyp Display';
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      letter-spacing: 0.01em;
      padding-top: 16px;
      padding-bottom: 12px;
      width: 211px;
    }

    &_subtitle {
      font-family: 'Neue Montreal';
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.01em;

      width: 222px;
    }

    &_image {
      position: relative;
      top: -94%;
      left: 55%;
    }
  }

  .collapse {
    padding: 16px 0;
    border-bottom: 1px dashed rgba(226, 230, 230, 1);
    width: 70%;
    margin: auto;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    max-height: 75px;

    &_title {
      width: 254px;
      font-family: 'Atyp Display';
      font-size: 32px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: -0.02em;
      margin-left: 15%;
      margin-top: 60px;
      margin-bottom: 40px;
    }

    &-main {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &_questions {
        font-family: 'Atyp Display';
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.01em;
      }

      button {
        background: none;
        border: none;
      }
    }

    &-answer {
      padding: 10px;
      background-color: #fff;

      &_answer {
        display: none;
        @include font-small;
        width: 90%;
        margin-top: 8px;
      }
    }
  }

  .collapse.open {
    max-height: 500px;
  }

  .collapse.open .collapse-answer_answer {
    display: block;
  }

  .footer {
    margin-bottom: 100px;
    margin-top: 100px;

    h1 {
      font-family: 'Atyp Display';
      font-size: 36px;
      font-weight: 600;
      line-height: 36px;
      letter-spacing: -0.02em;
      text-align: center;
      width: 199px;
      margin: auto;

      span {
        color: #32CCCC;
      }
    }

    button {
      margin: auto;
      margin-top: 32px;
    }
  }

  .contact {
    padding-top: 100px;
    margin-bottom: 200px;

    input,
    textarea {
      padding: 12px 0 12px 20px;
      border: 1px solid rgba(226, 230, 230, 1);
      background: rgba(247, 249, 249, 1);
      color: rgba(4, 4, 4, 1);
      border-radius: 24px;
      outline: none;
      font-family: 'Neue Montreal';
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
    }

    input::placeholder,
    textarea::placeholder {
      color: rgba(156, 160, 160, 1);
      font-family: 'Neue Montreal';
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
    }

    input.error,
    textarea.error {
      border: 2px solid rgba(235, 87, 87, 1);
      color: rgba(235, 87, 87, 1);
    }

    input.error::placeholder,
    textarea.error::placeholder {
      color: rgba(235, 87, 87, 1);
    }

    &-main {
      width: 60%;
      margin: auto;

      &_title {
        @include font-title;
        text-align: center;
        margin-bottom: 12px;
      }

      &_subtitle {
        @include font-subtitle;
        text-align: center;
      }
    }

    &-form {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      width: 60%;
      margin: auto;
      margin-top: 56px;

      &-info {
        width: 100%;
        display: flex;

        &_name {
          margin-right: 20px;
          width: 50%;
        }

        &_surname {
          width: 50%;
        }
      }

      &_email {
        width: 100%;
        margin-top: 24px;
      }

      &_message {
        width: 100%;
        height: 114px;
        margin-top: 24px;
        resize: none;
      }

      &_btn {
        width: 100%;
        padding-top: 12px;
        padding-bottom: 13px;
        border: none;
        color: rgba(4, 4, 4, 1);
        background: rgba(50, 204, 204, 1);
        border-radius: 24px;
        font-family: 'Neue Montreal';
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0.01em;
        margin-top: 32px;
        transition: 0.4s;
      }

      &_btn:hover {
        color: #fefefe;
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    margin: 40px 10%;

    &-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 300px;

      &_li {
        list-style: none;

        a {
          font-family: 'Neue Montreal';
          font-size: 13px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0.01em;
          text-decoration: none;
          color: rgba(86, 89, 89, 1);
          transition: 1s;
        }

        a:hover {
          color: #32CCCC;
        }
      }
    }

    &-social {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 170px;

      &_li {
        list-style: none;
      }
    }
  }
}

@media screen and (min-width: 1440px) and (max-width: 1600px) {
  .header-menu_btn {
    display: none;
  }

  .menuBtn {
    display: none;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    padding: 20px 10% 28px;

    &-menu {
      width: 257px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: center;
      margin-left: 48px;

      &_li {
        list-style: none;

        a {
          @include font-small;
          text-decoration: none;
          color: rgba(4, 4, 4, 1);
        }
      }
    }

    &-login {

      &_btn {
        border: 1.5px solid rgba(50, 204, 204, 1);
        padding: 13px 32px;
        background: none;
        border-radius: 24px;
        transition: 0.3s;

        a {
          font-family: "Neue Montreal";
          font-weight: 600;
          font-size: 16px;
          text-decoration: none;
          line-height: 22px;
          color: rgba(4, 4, 4, 1);
        }
      }

      &_btn:hover {
        background: rgba(50, 204, 204, 1);
      }
    }
  }

  .sec1 {
    display: flex;
    margin: 120px 10% 100px;
    background-image: url(./assets/header-bg.png);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 50%;

    &-left {

      &_title {
        width: 50%;
        @include font-big;
        color: rgba(4, 4, 4, 1);
        margin-bottom: 80px;
        margin-top: 30px;

        span {
          color: rgba(50, 204, 204, 1);
        }
      }

      &_subtitle {
        margin-bottom: 40px;
        @include font-subtitle;
      }

      &_text {
        font-family: "Neue Montreal";
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.01em;
      }

      &_btn {
        margin-top: 16px;
        padding: 16px 32px;
        border: none;
        border-radius: 26px;
        background: rgba(50, 204, 204, 1);
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: center;

        p {
          font-family: "Neue Montreal";
          font-size: 16px;
          font-weight: 600;
          line-height: 19px;
          letter-spacing: 0em;
          margin-right: 10px;
          transition: 0.4s;
        }
      }

      &_btn:hover {
        p {
          color: #fff;
        }
      }

      &_arrow {
        position: relative;
        top: -80px;
        left: 198px;
      }
    }
  }

  .secondary {
    display: flex;
    background-color: rgba(50, 204, 204, 0.1);
    background-image: url(./assets/secondary-mainbg.png);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 40%;
    background-position-x: 90%;
    padding-left: 10%;

    &-main {
      padding-top: 100px;
      padding-bottom: 100px;

      &-left {

        &_text {
          margin: 32px 0 39px;

          &__title {
            @include font-title;
            margin-bottom: 32px;
            width: 60%;

            span {
              color: rgba(50, 204, 204, 1);
            }
          }

          &__subtitle {
            @include font-subtitle;
            width: 629px;
          }
        }

        &_btn {
          font-family: 'Neue Montreal';
          font-size: 16px;
          font-weight: 600;
          line-height: 19px;
          letter-spacing: 0em;
          padding: 16.5px 32px;
          border: none;
          border-radius: 26px;
          background: rgba(50, 204, 204, 1);
          transition: 0.4s;
        }

        &_btn:hover {
          color: #fff;
        }
      }
    }

  }

  .sec2 {
    background: linear-gradient(180deg, #32CCCC 14.06%, rgba(50, 204, 204, 0) 79.69%);
    padding: 100px 10% 0;
    margin-bottom: 100px;

    &-main {
      background-image: url(./assets/sec2-mainbg.png);
      background-repeat: no-repeat;
      background-size: 50%;
      background-position: center right;
      display: flex;
      padding-bottom: 96px;

      &-left {

        &_text {
          &__title {
            @include font-big;
            color: rgba(4, 4, 4, 1);
            width: 440px;

            span {
              color: rgba(255, 255, 255, 1);
            }
          }

          &__subtitle {
            @include font-small;
            width: 421px;
            margin-top: 24px;
            margin-bottom: 40px;
          }
        }

        &_btn {
          padding: 16.5px 32px;
          border: none;
          border-radius: 26px;
          background: #FFF;
          font-family: 'Neue Montreal';
          font-size: 16px;
          font-weight: 600;
          line-height: 19px;
          letter-spacing: 0em;
          transition: 0.4s;
        }

        &_btn:hover {
          color: rgba(50, 204, 204, 1);
        }
      }

      margin-bottom: 100px;
    }

    &-cards {
      display: flex;
      justify-content: space-between;
    }
  }

  .card {
    padding: 24px;
    padding-bottom: 32px;
    background: #fff;
    border-radius: 12px;
    width: 31%;
    margin-right: 24px;
    margin-bottom: 24px;
    border: 1px dashed rgba(226, 230, 230, 1);
    background-image: url(./assets/card-bg.png);
    background-repeat: no-repeat;
    background-position: top right;

    height: 300px;

    &_icon {
      border: none;
    }

    &_title {
      font-family: 'Atyp Display';
      font-size: 24px;
      font-weight: 600;
      line-height: 31px;
      letter-spacing: 0.01em;
      width: 80%;
      margin-top: 16px;
      margin-bottom: 12px;
    }

    &_subtitle {
      @include font-small;
      width: 95%;
    }
  }

  .sec3 {
    margin: 0 10% 100px;
    padding-top: 100px;

    &-main {
      background-image: url(./assets//sec3-mainbg.png);
      background-repeat: no-repeat;
      background-size: 50%;
      background-position: center right;

      &-left {
        &_text {
          &__title {
            @include font-big;
            margin-top: 30px;
            margin-bottom: 24px;
            width: 440px;

            span {
              color: rgba(50, 204, 204, 1);
            }
          }

          &__subtitle {
            @include font-small;
            margin-bottom: 40px;
            width: 421px;
          }
        }

        &_btn {
          font-family: 'Neue Montreal';
          font-size: 16px;
          font-weight: 600;
          line-height: 19px;
          letter-spacing: 0em;
          padding: 16.5px 32px;
          border: none;
          border-radius: 26px;
          color: rgba(4, 4, 4, 1);
          background: rgba(50, 204, 204, 1);
          margin-bottom: 85px;
          transition: 0.4s;
        }

        &_btn:hover {
          color: #fff;
        }
      }
    }

    &-cards {
      margin-top: 100px;

      &-row {
        display: flex;
      }
    }
  }

  .SecondaryCard {
    padding: 24px;
    border: 1px dashed rgba(226, 230, 230, 1);
    border-radius: 12px;
    margin: 12px;
    width: 32%;

    &_bg {
      width: 100%;
    }

    &_text {
      &__title {
        font-family: 'Atyp Display';
        font-size: 24px;
        font-weight: 600;
        line-height: 31px;
        letter-spacing: 0.01em;
        margin-top: 24px;
        margin-bottom: 12px;
        width: 90%;
      }

      &__subtitle {
        @include font-small;
        width: 100%;
      }
    }
  }

  .sec4 {
    padding: 100px 10% 0;
    background-image: url(./assets/sec4-mainbg.png);
    background-repeat: no-repeat;
    background-size: cover;

    &-main {
      background-image: url(./assets/sec4-seconbg.png);
      background-size: 50%;
      background-repeat: no-repeat;
      background-position: top right;
      padding-bottom: 185px;

      &-left {

        &_text {
          &__title {
            @include font-big;
            color: rgba(4, 4, 4, 1);
            width: 428px;

            span {
              color: rgba(255, 255, 255, 1);
            }
          }

          &__subtitle {
            @include font-small;
            margin-top: 24px;
            margin-bottom: 40px;
            width: 421px;
          }
        }

        &_btn {
          font-family: 'Neue Montreal';
          font-size: 16px;
          font-weight: 600;
          line-height: 19px;
          letter-spacing: 0em;
          border: none;
          border-radius: 26px;
          color: rgba(4, 4, 4, 1);
          background: rgba(255, 255, 255, 1);
          padding: 16.5px 32px;
          transition: 0.4s;
        }

        &_btn:hover {
          color: rgba(50, 204, 204, 1);
        }
      }
    }

    &-cards {
      &-row {
        display: flex;
      }
    }
  }

  .ThirdCard {
    padding: 24px;
    background: #fff;
    width: 32%;
    border: 1px dashed rgba(226, 230, 230, 1);
    border-radius: 12px;
    height: 282px;
    margin: 12px;
    margin-bottom: 100px;

    &_title {
      font-family: 'Atyp Display';
      font-size: 24px;
      font-weight: 600;
      line-height: 31px;
      letter-spacing: 0.01em;
      padding-top: 16px;
      padding-bottom: 12px;
      width: 90%;
    }

    &_subtitle {
      @include font-small;
      width: 100%;
    }

    &_image {
      position: relative;
      top: -85%;
      left: 57%;
    }
  }

  .collapse {
    padding: 16px 0;
    border-bottom: 1px dashed rgba(226, 230, 230, 1);
    width: 50%;
    margin: auto;
    overflow: hidden;
    transition: max-height 1s ease-in-out;
    max-height: 55px;

    &_title {
      width: 380px;
      font-family: 'Atyp Display';
      font-size: 48px;
      font-weight: 600;
      line-height: 48px;
      letter-spacing: -0.02em;
      margin-left: 25%;
      margin-top: 160px;
      margin-bottom: 40px;
    }

    &-main {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &_questions {
        font-family: 'Atyp Display';
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0.01em;
      }

      button {
        background: none;
        border: none;
      }
    }

    &-answer {
      padding: 10px;
      background-color: #fff;

      &_answer {
        @include font-small;
        width: 559px;
        margin-top: 8px;
      }
    }
  }

  .collapse.open {
    max-height: 500px;
  }

  .footer {
    background-image: url(./assets/footer-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 232px;
    margin-top: 100px;

    h1 {
      @include font-big;
      text-align: center;
      width: 353px;
      margin: auto;

      span {
        color: #32CCCC;
      }
    }

    button {
      margin: auto;
      margin-top: 32px;
      margin-bottom: 200px;
    }
  }

  .contact {
    padding-top: 100px;
    margin-bottom: 200px;

    input,
    textarea {
      padding: 12px 0 12px 20px;
      border: 1px solid rgba(226, 230, 230, 1);
      background: rgba(247, 249, 249, 1);
      color: rgba(4, 4, 4, 1);
      border-radius: 24px;
      outline: none;
      font-family: 'Neue Montreal';
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
    }

    input::placeholder,
    textarea::placeholder {
      color: rgba(156, 160, 160, 1);
      font-family: 'Neue Montreal';
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
    }

    input.error,
    textarea.error {
      border: 2px solid rgba(235, 87, 87, 1);
      color: rgba(235, 87, 87, 1);
    }

    input.error::placeholder,
    textarea.error::placeholder {
      color: rgba(235, 87, 87, 1);
    }

    &-main {
      width: 30%;
      margin: auto;

      &_title {
        @include font-title;
        text-align: center;
        margin-bottom: 12px;
      }

      &_subtitle {
        @include font-subtitle;
        text-align: center;
      }
    }

    &-form {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      width: 30%;
      margin: auto;
      margin-top: 56px;

      &-info {
        width: 100%;
        display: flex;

        &_name {
          margin-right: 20px;
          width: 50%;
        }

        &_surname {
          width: 50%;
        }
      }

      &_email {
        width: 100%;
        margin-top: 24px;
      }

      &_message {
        width: 100%;
        height: 114px;
        margin-top: 24px;
        resize: none;
      }

      &_btn {
        width: 100%;
        padding-top: 12px;
        padding-bottom: 13px;
        border: none;
        color: rgba(4, 4, 4, 1);
        background: rgba(50, 204, 204, 1);
        border-radius: 24px;
        font-family: 'Neue Montreal';
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0.01em;
        margin-top: 32px;
        transition: 0.4s;
      }

      &_btn:hover {
        color: #fefefe;
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    margin: 40px 10%;

    &-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 300px;

      &_li {
        list-style: none;

        a {
          font-family: 'Neue Montreal';
          font-size: 13px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0.01em;
          text-decoration: none;
          color: rgba(86, 89, 89, 1);
          transition: 1s;
        }

        a:hover {
          color: #32CCCC;
        }
      }
    }

    &-social {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: center;
      width: 170px;

      &_li {
        list-style: none;
      }
    }
  }

  .modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);

    &-content {
      background-color: #fefefe;
      margin: 15% auto;
      padding: 20px;
      border: none;
      width: 512px;
      border-radius: 24px;
      padding: 12px 48px 96px;

      &-text {
        display: flex;
        flex-direction: column;
        align-items: center;

        &_title {
          font-family: 'Atyp Display';
          font-size: 36px;
          font-weight: 600;
          line-height: 36px;
          letter-spacing: -0.02em;
          margin-top: 64px;
          width: 272px;
        }

        &_sub {
          @include font-small;
          text-align: center;
          width: 352px;
          margin-top: 16px;
          margin-bottom: 32px;
        }
      }

      &-join {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        align-self: center;


        &_input {
          padding-top: 12px;
          padding-bottom: 12px;
          background: rgba(247, 249, 249, 1);
          border: 1px solid rgba(226, 230, 230, 1);
          border-radius: 24px;
          background-image: url(./assets/loginModalMail.png);
          background-repeat: no-repeat;
          background-position-y: center;
          background-position-x: 20px;
          padding-left: 52px;
        }

        &_input:focus-visible {
          border: 1px solid #32CCCC !important;
          outline: none;
        }

        &_btn {
          font-family: 'Neue Montreal';
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 0.01em;
          border: none;
          background: rgba(50, 204, 204, 1);
          border-radius: 24px;
          padding: 13px 32px;
        }
      }
    }

    &-enter {
      opacity: 0;

      &-active {
        opacity: 1;
        transition: opacity 1s;
      }
    }

    &-exit {
      opacity: 1;

      &-active {
        opacity: 0;
        transition: opacity 1s;
      }
    }
  }

  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
  }

  .open {
    display: block;
  }
}

@media screen and (min-width: 1600px) {
  .header-menu_btn {
    display: none;
  }

  .menuBtn {
    display: none;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    padding: 20px 20% 28px;

    &-menu {
      width: 257px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: center;
      margin-left: 48px;

      &_li {
        list-style: none;

        a {
          text-decoration: none;
          font-family: 'Neue Montreal';
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: rgba(4, 4, 4, 1);
        }
      }
    }

    &-login {

      &_btn {
        border: 1.5px solid rgba(50, 204, 204, 1);
        padding: 13px 32px;
        background: none;
        border-radius: 24px;
        transition: 0.3s;

        a {
          font-family: "Neue Montreal";
          font-weight: 600;
          font-size: 16px;
          text-decoration: none;
          line-height: 22px;
          color: rgba(4, 4, 4, 1);
        }
      }

      &_btn:hover {
        background: rgba(50, 204, 204, 1);
      }
    }
  }

  .sec1 {
    display: flex;
    background-image: url(./assets/header-bg.png);
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: center right;
    margin: 100px 20% 120px;

    &-left {

      &_title {
        width: 490px;
        @include font-big;
        color: rgba(4, 4, 4, 1);
        margin-bottom: 100px;
        margin-top: 30px;

        span {
          color: rgba(50, 204, 204, 1);
        }
      }

      &_subtitle {
        margin-bottom: 48px;
        @include font-subtitle;
      }

      &_text {
        font-family: "Neue Montreal";
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.01em;
      }

      &_btn {
        margin-top: 16px;
        padding: 16px 32px;
        border: none;
        border-radius: 26px;
        background: rgba(50, 204, 204, 1);
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: center;

        p {
          font-family: "Neue Montreal";
          font-size: 16px;
          font-weight: 600;
          line-height: 19px;
          letter-spacing: 0em;
          margin-right: 10px;
          transition: 0.4s;
        }
      }

      &_btn:hover {
        p {
          color: #fff;
        }
      }

      &_arrow {
        position: relative;
        top: -80px;
        left: 198px;
      }
    }

    &-right {
      margin-left: 15%;
      margin-top: 20px;

      &-images {
        display: flex;

        &_img2 {
          width: 100%;
          height: 100%;
          position: relative;
          left: -472px;
          top: 63px;
        }
      }
    }
  }

  .secondary {
    display: flex;
    background-color: rgba(50, 204, 204, 0.1);
    padding-right: 10%;
    padding-left: 20%;

    &-main {
      padding-top: 100px;
      padding-bottom: 160px;
      background-image: url(./assets/secondary-mainbg.png);
      background-repeat: no-repeat;
      background-size: 50%;
      background-position: center right;
      width: 100%;

      &-left {

        &_text {
          margin-top: 32px;
          margin-bottom: 39px;

          &__title {
            @include font-title;
            margin-bottom: 32px;
            width: 820px;

            span {
              color: rgba(50, 204, 204, 1);
            }
          }

          &__subtitle {
            @include font-subtitle;
            width: 629px;
          }
        }

        &_btn {
          font-family: 'Neue Montreal';
          font-size: 16px;
          font-weight: 600;
          line-height: 19px;
          letter-spacing: 0em;
          padding: 16.5px 32px;
          border: none;
          border-radius: 26px;
          background: rgba(50, 204, 204, 1);
          transition: 0.4s;
        }

        &_btn:hover {
          color: #fff;
        }
      }
    }

  }

  .sec2 {
    background: linear-gradient(180deg, #32CCCC 40%, rgba(50, 204, 204, 0) 79.69%);
    padding: 100px 20%;

    &-main {
      display: flex;
      background-image: url(./assets/sec2-mainbg.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center right;
      margin-bottom: 100px;

      &-left {

        &_text {
          &__title {
            @include font-big;
            color: rgba(4, 4, 4, 1);
            width: 440px;

            span {
              color: rgba(255, 255, 255, 1);
            }
          }

          &__subtitle {
            @include font-small;
            width: 421px;
            margin-top: 24px;
            margin-bottom: 40px;
          }
        }

        &_btn {
          padding: 16.5px 32px;
          border: none;
          border-radius: 26px;
          background: #FFF;
          font-family: 'Neue Montreal';
          font-size: 16px;
          font-weight: 600;
          line-height: 19px;
          letter-spacing: 0em;
          transition: 0.4s;
          margin-bottom: 95px;
        }

        &_btn:hover {
          color: rgba(50, 204, 204, 1);
        }
      }

      &-right {
        margin-left: 15%;
        margin-top: 100px;
      }

    }

    &-cards {
      display: flex;
    }
  }

  .card {
    padding: 24px;
    padding-bottom: 32px;
    background: #fff;
    border-radius: 12px;
    width: 31%;
    margin-right: 24px;
    margin-bottom: 24px;
    border: 1px dashed rgba(226, 230, 230, 1);
    background-image: url(./assets/card-bg.png);
    background-repeat: no-repeat;
    background-position: top right;

    height: 300px;

    &_icon {
      border: none;
    }

    &_title {
      font-family: 'Atyp Display';
      font-size: 24px;
      font-weight: 600;
      line-height: 31px;
      letter-spacing: 0.01em;
      width: 100%;
      margin-top: 16px;
      margin-bottom: 12px;
    }

    &_subtitle {
      @include font-small;
      width: 100%;
    }
  }

  .sec3 {
    margin: 0 20% 100px;
    padding-top: 100px;

    &-main {
      background-image: url(./assets/sec3-mainbg.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center right;

      &-left {
        &_text {
          &__title {
            @include font-big;
            margin-top: 30px;
            margin-bottom: 24px;
            width: 440px;

            span {
              color: rgba(50, 204, 204, 1);
            }
          }

          &__subtitle {
            @include font-small;
            margin-bottom: 40px;
            width: 421px;
          }
        }

        &_btn {
          font-family: 'Neue Montreal';
          font-size: 16px;
          font-weight: 600;
          line-height: 19px;
          letter-spacing: 0em;
          padding: 16.5px 32px;
          border: none;
          border-radius: 26px;
          color: rgba(4, 4, 4, 1);
          background: rgba(50, 204, 204, 1);
          margin-bottom: 85px;
          transition: 0.4s;
        }

        &_btn:hover {
          color: #fff;
        }
      }
    }

    &-cards {
      margin: auto;
      margin-top: 100px;

      &-row {
        display: flex;
      }
    }
  }

  .SecondaryCard {
    padding: 24px;
    border: 1px dashed rgba(226, 230, 230, 1);
    border-radius: 12px;
    margin-right: 24px;
    width: 30%;

    img {
      width: 100%;
    }

    &_text {
      &__title {
        font-family: 'Atyp Display';
        font-size: 24px;
        font-weight: 600;
        line-height: 31px;
        letter-spacing: 0.01em;
        margin-top: 24px;
        margin-bottom: 12px;
        width: 90%;
      }

      &__subtitle {
        @include font-small;
        width: 95%;
      }
    }
  }

  .sec4 {
    padding: 0 20%;
    background-image: url(./assets/sec4-mainbg.png);
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    padding-top: 100px;

    &-main {
      display: flex;
      background-image: url(./assets/sec4-seconbg.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center right;
      margin-bottom: 100px;
      padding-bottom: 95px;

      &-left {

        &_text {
          &__title {
            @include font-big;
            color: rgba(4, 4, 4, 1);
            width: 428px;

            span {
              color: rgba(255, 255, 255, 1);
            }
          }

          &__subtitle {
            @include font-small;
            margin-top: 24px;
            margin-bottom: 40px;
            width: 421px;
          }
        }

        &_btn {
          font-family: 'Neue Montreal';
          font-size: 16px;
          font-weight: 600;
          line-height: 19px;
          letter-spacing: 0em;
          border: none;
          border-radius: 26px;
          color: rgba(4, 4, 4, 1);
          background: rgba(255, 255, 255, 1);
          padding: 16.5px 32px;
          transition: 0.4s;
        }

        &_btn:hover {
          color: rgba(50, 204, 204, 1);
        }
      }

      &-right {
        margin-top: 100px;
        margin-left: 20%;
        margin-bottom: 100px;
      }
    }

    &-cards {
      &-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  .ThirdCard {
    padding: 24px;
    background: #fff;
    width: 32%;
    border: 1px dashed rgba(226, 230, 230, 1);
    border-radius: 12px;
    height: 329px;
    margin-bottom: 100px;
    margin-right: 12px;
    margin-left: 12px;

    &_title {
      font-family: 'Atyp Display';
      font-size: 24px;
      font-weight: 600;
      line-height: 31px;
      letter-spacing: 0.01em;
      padding-top: 28px;
      padding-bottom: 20px;
      width: 80%;
    }

    &_subtitle {
      @include font-small;
      width: 100%;
    }

    &_image {
      display: none;
    }

    &-1 {
      background-image: url(./assets/first-img-card.png);
      background-repeat: no-repeat;
      background-position: top right;
    }

    &-2 {
      background-image: url(./assets/sec-img-card.png);
      background-repeat: no-repeat;
      background-position: top right;
    }

    &-3 {
      background-image: url(./assets/thrd-img-card.png);
      background-repeat: no-repeat;
      background-position: top right;
    }

  }

  .collapse {
    padding: 16px;
    border-bottom: 1px dashed rgba(226, 230, 230, 1);
    width: 45%;
    margin: auto;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    max-height: 55px;

    &_title {
      width: 380px;
      font-family: 'Atyp Display';
      font-size: 48px;
      font-weight: 600;
      line-height: 48px;
      letter-spacing: -0.02em;
      margin-left: 31%;
      margin-top: 160px;
      margin-bottom: 40px;
    }

    &-main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      &_questions {
        font-family: 'Atyp Display';
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0.01em;
      }

      button {
        background: none;
        border: none;
      }
    }

    &-answer {
      padding: 10px;
      background-color: #fff;

      &_answer {
        @include font-small;
        width: 100%;
        margin-top: 8px;
      }
    }

  }

  .collapse.open {
    max-height: 500px;
  }

  .footer {
    background-image: url(./assets/footer-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 232px;
    margin-top: 100px;

    h1 {
      @include font-big;
      text-align: center;
      width: 353px;
      margin: auto;

      span {
        color: #32CCCC;
      }
    }

    button {
      margin: auto;
      margin-top: 32px;
      margin-bottom: 200px;
    }
  }

  .contact {
    padding-top: 100px;
    margin-bottom: 200px;

    input,
    textarea {
      padding: 12px 0 12px 20px;
      border: 1px solid rgba(226, 230, 230, 1);
      background: rgba(247, 249, 249, 1);
      color: rgba(4, 4, 4, 1);
      border-radius: 24px;
      outline: none;
      font-family: 'Neue Montreal';
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
    }

    input::placeholder,
    textarea::placeholder {
      color: rgba(156, 160, 160, 1);
      font-family: 'Neue Montreal';
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
    }

    input.error,
    textarea.error {
      border: 2px solid rgba(235, 87, 87, 1);
      color: rgba(235, 87, 87, 1);
    }

    input.error::placeholder,
    textarea.error::placeholder {
      color: rgba(235, 87, 87, 1);
    }

    &-main {
      width: 30%;
      margin: auto;

      &_title {
        @include font-title;
        text-align: center;
        margin-bottom: 12px;
      }

      &_subtitle {
        @include font-subtitle;
        text-align: center;
      }
    }

    &-form {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      width: 30%;
      margin: auto;
      margin-top: 56px;

      &-info {
        width: 100%;
        display: flex;

        &_name {
          margin-right: 20px;
          width: 50%;
        }

        &_surname {
          width: 50%;
        }
      }

      &_email {
        width: 100%;
        margin-top: 24px;
      }

      &_message {
        width: 100%;
        height: 114px;
        margin-top: 24px;
        resize: none;
      }

      &_btn {
        width: 100%;
        padding-top: 12px;
        padding-bottom: 13px;
        border: none;
        color: rgba(4, 4, 4, 1);
        background: rgba(50, 204, 204, 1);
        border-radius: 24px;
        font-family: 'Neue Montreal';
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0.01em;
        margin-top: 32px;
        transition: 0.4s;
      }

      &_btn:hover {
        color: #fefefe;
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    margin: 40px 20%;

    &-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 300px;

      &_li {
        list-style: none;

        a {
          font-family: 'Neue Montreal';
          font-size: 13px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0.01em;
          text-decoration: none;
          color: rgba(86, 89, 89, 1);
          transition: 1s;
        }

        a:hover {
          color: #32CCCC;
        }
      }
    }

    &-social {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: center;
      width: 170px;

      &_li {
        list-style: none;
      }
    }
  }


  .modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);

    &-content {
      background-color: #fefefe;
      margin: 15% auto;
      padding: 20px;
      border: none;
      width: 512px;
      border-radius: 24px;
      padding: 12px 48px 96px;

      &-text {
        display: flex;
        flex-direction: column;
        align-items: center;

        &_title {
          font-family: 'Atyp Display';
          font-size: 36px;
          font-weight: 600;
          line-height: 36px;
          letter-spacing: -0.02em;
          margin-top: 64px;
          width: 272px;
        }

        &_sub {
          @include font-small;
          text-align: center;
          width: 352px;
          margin-top: 16px;
          margin-bottom: 32px;
        }
      }

      &-join {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        align-self: center;


        &_input {
          padding-top: 12px;
          padding-bottom: 12px;
          background: rgba(247, 249, 249, 1);
          border: 1px solid rgba(226, 230, 230, 1);
          border-radius: 24px;
          background-image: url(./assets/loginModalMail.png);
          background-repeat: no-repeat;
          background-position-y: center;
          background-position-x: 20px;
          padding-left: 52px;
        }

        &_input:focus-visible {
          border: 1px solid #32CCCC !important;
          outline: none;
        }

        &_btn {
          font-family: 'Neue Montreal';
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 0.01em;
          border: none;
          background: rgba(50, 204, 204, 1);
          border-radius: 24px;
          padding: 13px 32px;
        }
      }
    }

    &-enter {
      opacity: 0;

      &-active {
        opacity: 1;
        transition: opacity 1s;
      }
    }

    &-exit {
      opacity: 1;

      &-active {
        opacity: 0;
        transition: opacity 1s;
      }
    }
  }

  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
  }

  .open {
    display: block;
  }
}